import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import YandexMetrika from './Components/YandexMetrika';


function App() {
  return (
    <div className="App">
      <YandexMetrika />
      <Container>
        <Row>
          <Col>
            <div className='black-rounded mb-3'>
              <h1>Лисенков Андрей</h1>
              <div className="contacts d-flex flex-column align-items-start gap-3">
                <div className="d-flex align-items-center mb-2">
                  <img src="./img/icons/gmail.svg" width="32px" alt="email" className="me-2" />
                  <a href="mailto:an.vi.lisenkov@gmail.com" target='_blank'>an.vi.lisenkov@gmail.com</a>
                </div>
                <div className="d-flex align-items-center">
                  <img src="./img/icons/telegram.svg" width="32px" alt="telegram" className="me-2" />
                  <a href="https://t.me/anvilis" target='_blank'>@anvilis</a>
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="black-rounded">
              <h2>Обо мне</h2>
              <p>
                Проектный менеджер с опытом в digital-продакшене, разработке сайтов и управлении рекламными кампаниями.
                Работал с крупными клиентами, выстраивая эффективные процессы от сбора требований до запуска сложных digital-проектов.
                Умею координировать команду, структурировать задачи и следить за их реализацией в срок.
                Мой опыт охватывает веб-разработку, UX/UI проектирование, интеграцию новых сервисов и техническую координацию.
                Разбираюсь в маркетинговых стратегиях, понимаю бизнес-задачи клиентов и помогаю трансформировать их в работающие digital-решения.
              </p>
            </div>
          </Col>
        </Row>

        <Row className='mt-3 d-flex align-items-stretch'>
          <Col xs={12} md={4} className='d-flex flex-column  work-experience-small mb-3'>
            <div className='black-rounded'>
              <h3>Pena</h3>
              <p>1 год</p>
              <p>веб разработка</p>
              <p>ui/ux проектирование и дизайн</p>
              <p>креатив, баннерные кампании</p>
            </div>
          </Col>
          <Col xs={12} md={4} className='d-flex flex-column work-experience-small mb-3'>
            <div className='black-rounded'>
              <h3>Freelance</h3>
              <p>Дизайн и UI/UX</p>
              <p>Разработка 3D моделей</p>
              <p>Монтаж роликов (UGC/SMM)</p>
            </div>
          </Col>
          <Col xs={12} md={4} className='d-flex flex-column work-experience-small mb-3'>
            <div className='black-rounded'>
              <h3>OMD OM Group</h3>
              <small>Omnicom Group</small>
              <p>3 года</p>
              <p>веб разработка</p>
              <p>креатив, баннерные кампании</p>
            </div>
          </Col>
        </Row>
        <Row className='mt-3 d-flex align-items-stretch'>
          <Col xs={12} md={4} className='d-flex flex-column work-experience-small mb-3'>
            <div className='black-rounded'>
              <h3>АНО Национальные
                приоритеты</h3>
              <p>5 мес</p>
              <p>разработка и техническое
                сопровождение проекта объясняем.рф
                и внутренних проектов</p>
            </div>
          </Col>
          <Col xs={12} md={4} className='d-flex flex-column work-experience-small mb-3'>
            <div className='black-rounded'>
              <h3>Engage</h3>
              <small>Publicis Group</small>
              <p>1 год</p>
              <p>Digital сопровождение nissan.ru</p>
            </div>
          </Col>

          <Col xs={12} md={4} className='d-flex flex-column work-experience-small mb-3'>
            <div className='black-rounded'>
              <h3>Empis</h3>
              <p>2 годa</p>
              <p>веб разработка
                e-com сайтов на bitrix, wp</p>
            </div>
          </Col>
        </Row>
        <Row>
          <h2 className='section-h2'>Навыки</h2>
          {/* <img className="img-fluid mb-3" src="./img/skills.png" alt="skills" /> */}
        </Row>
        <Container className='d-flex flex-column gap-3'>
          <Row className='black-rounded skills-border-management d-flex flex-md-row'>
            <Col xs={12} md={4}>
              <div>
                <h3>Менеджмент проектов</h3>
                <div className='tagsContainer d-flex fluid'>
                  <span className='skillTag'>Управление digital-проектами</span>
                  <span className='skillTag'>Координация команды</span>
                  <span className='skillTag'>Постановка задач</span>
                  <span className='skillTag'>Взаимодействие со стейкхолдерами</span>
                </div>
              </div>
            </Col>
            <Col xs={12} md={8}>
              В этой роли я управлял проектами различных типов. Все проекты включали в себя полный цикл работы: от взаимодействия со стейкхолдерами и выявления требований до координации выполнения задач командой. Мой опыт охватывает работу с клиентами, подготовку тендерных предложений, а также контроль за соблюдением сроков и бюджета. Специализируюсь на эффективном распределении задач, управлении рисками и создании процесса обратной связи для улучшения качества работы.
            </Col>
          </Row>

          <Row className='black-rounded skills-border-tech d-flex flex-md-row'>
            <Col xs={12} md={4}>
              <div>
                <h3>Технические навыки</h3>
                <div className='tagsContainer'>
                  <span className='skillTag'>Html + CSS</span>
                  <span className='skillTag'>JavaScript</span>
                  <span className='skillTag'>React</span>
                  <span className='skillTag'>Работа с API</span>
                  <span className='skillTag'>Тестирование</span>
                </div>
              </div>
            </Col>

            <Col xs={12} md={8}>
              Обладаю уверенными техническими знаниями, включая опыт работы с API и интеграцию внешних сервисов в корпоративные системы. Умею разбираться в документированных сервисах, проводить их анализ и расставлять задачи для команды разработки. Также в моих компетенциях верстка сайтов, базовые знания JavaScript и React, что позволяет мне участвовать в разработке интерфейсов и решать задачи по оптимизации. Знаю, как работают DNS и серверы, что помогает мне объяснить эти моменты клиентам или проверить их самостоятельно. Я также работаю с инструментами для тестирования API, такими как Postman.
            </Col>
          </Row>


          <Row className='black-rounded skills-border-design d-flex flex-md-row'>
            <Col xs={12} md={4}>
              <div>
                <h3>Дизайн + motion</h3>
                <div className='tagsContainer'>
                  <span className='skillTag'>Photoshop</span>
                  <span className='skillTag'>Illustrator</span>
                  <span className='skillTag'>Figma</span>
                  <span className='skillTag'>After Effects</span>
                </div>
              </div>
            </Col>
            <Col xs={12} md={8}>
              В своей работе я занимался проектированием интерфейсов, создавал прототипы и макеты, а также корректировал работы дизайнеров с целью улучшения пользовательского опыта. Мои знания в области HTML и CSS позволяли интегрировать интерактивные элементы в дизайн, учитывая технические ограничения. В ряде случаев, когда требовалась дополнительная поддержка, я выполнял функции арт-директора, отвечая за выбор стиля и создание визуальных концепций. Также я имел опыт разработки логотипов, айдентики и монтажа видеороликов.
            </Col>
          </Row>

          <Row className='black-rounded skills-border-3d d-flex flex-md-row'>
            <Col xs={12} md={4}>
              <div>
                <h3>3D</h3>
                <div className='tagsContainer'>
                  <span className='skillTag'>Blender</span>
                  <span className='skillTag'>Cinema 4d</span>
                  <span className='skillTag'>Трекинг камеры</span>
                  <span className='skillTag'>Моделирование</span>
                  <span className='skillTag'>Анимации</span>
                </div>
              </div>
            </Col>
            <Col xs={12} md={8}>
              3D является моим хобби, которое я использую для решения задач в профессиональной сфере. Я применял знания 3D визуализации для создания рекламных материалов и улучшения проектов, включая 3D-баннеры и визуализации для брендов. Я взаимодействовал с подрядчиками, давая рекомендации по улучшению качества и контролируя весь процесс, также занимался 3D-иконками для карт и создания рекламных макетов. Мой опыт охватывает как техническую часть, так и артдирекшен, что позволило мне успешно вести проекты с высокой степенью сложности. В частности, я занимался 3D визуализацией для сайта Hate Agency.
            </Col>
          </Row>
        </Container>
        <Container className='d-flex flex-column gap-3'>
          <Row>
            <h2 className='section-h2'>Проекты</h2>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <img className="img-fluid mb-3" src="./img/projects/bericar.png" alt="project" />
            </Col>
            <Col xs={12} md={6}>

              <div className="black-rounded">
                <h4><a href='https://bericar.com/' target='_blank'>Bericar.com</a></h4>
                <p>Полный цикл проектирования и разработки:
                  от выявления бизнес-требований до создания интерфейсов.</p>
                <ul>
                  <li>Анализ и формализация требований клиента</li>
                  <li>Проектирование UX/UI: создание удобного и интуитивного интерфейса аукциона</li>
                  <li>Разработка дизайн-концепции и визуального стиля</li>
                  <li>Отрисовка дизайна всех экранов в мобильной и десктопной версии</li>
                </ul>
                <p>Результат: проект активно тестируется бизнесом</p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <img className="img-fluid mb-3" src="./img/projects/frys.png" alt="project" />
            </Col>
            <Col xs={12} md={6}>

              <div className="black-rounded">
                <h4><a href='https://frys.ru/' target='_blank'>Черноголовка - frys.ru</a></h4>
                <p>Fry’s – разработка продуктового сайта бренда компании Черноголовка</p>
                <ul>
                  <li>Курирование работы дизайнеров: постановка задач, контроль качества, финальное утверждение, арт-дирекшен</li>
                  <li>Подготовка тендерного предложения и защита концепции</li>
                  <li>Управление процессом разработки: координация команды, соблюдение сроков и бюджета</li>
                </ul>
                <p>Проект выиграл на тендере</p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <img className="img-fluid mb-3" src="./img/projects/cristelle.png" alt="project" />
            </Col>
            <Col xs={12} md={6}>

              <div className="black-rounded">
                <h4><a href='https://cristelle-water.ru/' target='_blank'>Черноголовка - cristelle-water.ru</a></h4>
                <p>Cristelle – разработка продуктового сайта бренда компании Черноголовка</p>
                <ul>
                  <li>Курирование работы дизайнеров: постановка задач, контроль качества, финальное утверждение, арт-дирекшен</li>
                  <li>Подготовка тендерного предложения и защита концепции</li>
                  <li>Управление процессом разработки: координация команды, соблюдение сроков и бюджета</li>
                </ul>
                <p>Проект выиграл на тендере</p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <img className="img-fluid mb-3" src="./img/projects/cryon.png" alt="project" />
            </Col>
            <Col xs={12} md={6}>

              <div className="black-rounded">
                <h4><a href='https://cryon.studio/' target='_blank'>cryon.studio</a></h4>
                <p>Разработка фирменного стиля, дизайна сайта, логотипа для студии коррекции фигуры</p>
                <ul>
                  <li>Дизайн логотипа и айдентики</li>
                  <li>Дизайн сайта (десктоп+мобайл)</li>
                  <li>Верстка и программирование (React + интеграция с YClients)</li>
                </ul>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <img className="img-fluid mb-3" src="./img/projects/majors-pari.png" alt="project" />
            </Col>
            <Col xs={12} md={6}>

              <div className="black-rounded">
                <h4>Pari - турнирный лендинг International (CS2)</h4>
                <p>Разработка лендинга формирования турниров CS2 по коэффициенту Бухгольца</p>
                <ul>
                  <li>Реверс инженеринг логики похожего проекта</li>
                  <li>Верстка проекта в соответствии с дизайном</li>
                  <li>Интеграция с данными клиента</li>
                </ul>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <img className="img-fluid mb-3" src="./img/projects/pandas.png" alt="project" />
            </Col>
            <Col xs={12} md={6}>

              <div className="black-rounded">
                <h4>Pari - промо лендинг 9Padnas</h4>
                <p>Разработка дизайна и иллюстраций для посадочной страницы</p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <img className="img-fluid mb-3 mb-3" src="./img/projects/kari-landing.png" alt="project" />
              <img className="img-fluid mb-3" src="./img/projects/kari-game.png" alt="project" />
            </Col>
            <Col xs={12} md={6}>

              <div className="black-rounded">
                <h4><a href="https://promo.kari.com/kariland_club/" target='_blank'>KARI KIDS - метавселенная Roblox</a></h4>
                <p>Разработка спецпроекта для Kari - метавселенная в Roblox</p>
                <p>
                  Kari KIDS: Экосистема бренда в метавселенной
                  Для бренда Kari KIDS команда мы в PENA Agency разработали экосистему, соединяющую различные каналы коммуникации, включая популярную игровую платформу Roblox, социальные сети (YouTube, Likee) и оффлайн-магазины. Важнейшими задачами стали донесение послания бренда до широкой аудитории, создание качественного контента и вовлечение детей в увлекательные игры. Экосистема включала мини-игры в Roblox, создание виртуальных игрушек и интеграцию программы лояльности, с возможностью получения бонусов через игру.
                  Активное сотрудничество с блогерами и запуск челленджей на платформе Likee обеспечили высокий уровень вовлеченности и расширение аудитории. Промо-акции с использованием видеоформатов и наружной рекламы позволили привлечь внимание к Kari KIDS, что привело к значительному увеличению продаж и росту узнаваемости бренда. В итоге сумма покупок на платформе составила более 2,9 миллионов рублей, а количество визитов на сайт и в игру значительно возросло.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <img className="img-fluid mb-3" src="./img/projects/takeda.png" alt="project" />
            </Col>
            <Col xs={12} md={6}>

              <div className="black-rounded">
                <h4>Takeda ID</h4>
                <p>Интеграция сервиса авторизации и регистрации для врачей и медицинских работников в цифровых продуктах компании Takeda</p>
                <p>
                  Проект Takeda ID заключался в разработке цифровой платформы для Takeda, которая должна была помочь компании управлять идентификацией пользователей, оптимизировать процессы и повысить удобство работы с данными. Основные задачи заключались в интеграции системы с существующими процессами и обеспечении простоты интерфейса. Особое внимание уделялось анализу пользовательского опыта и техническим особенностям платформы, что позволило создать решение, которое эффективно решает потребности бизнеса и пользователей.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <img className="img-fluid mb-3" src="./img/projects/unilever-multibrand.png" alt="project" />
            </Col>
            <Col xs={12} md={6}>

              <div className="black-rounded">
                <h4>Unilever - Новогодний лендинг на Яндексе</h4>
                <p>Спецпроект - разработка дизайна новогоднего лендинга в партнерстве с Яндекс</p>
                <p>
                  На данном проекте я курировал процесс разработки дизайна лендинга с учетом требований и ограничений площадки.
                </p>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className='d-flex flex-column gap-3'>
          <Row>
            <h2 className='section-h2'>Видео</h2>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <img className="img-fluid mb-3" src="./img/projects/axe-ai.png" alt="project" />
            </Col>
            <Col xs={12} md={6}>

              <div className="black-rounded">
                <h4>Unilever - AXE видео для AR пилонов</h4>
                <p>Спецпроект - разработка дизайна новогоднего лендинга в партнерстве с Яндекс</p>
                <a className="watch-button axe1-color" href="https://disk.yandex.ru/i/yMErXoskQEl3gg">Смотреть видео</a>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <img className="img-fluid mb-3" src="./img/projects/axe-chok.png" alt="project" />
            </Col>
            <Col xs={12} md={6}>

              <div className="black-rounded">
                <h4>Unilever - AXE 2D видео для 3D кубов</h4>
                <p>Разработка видеоролика для размещения на кубах с объемным эффектом.</p>
                <a className="watch-button axe2-color" href="https://disk.yandex.ru/i/0MTrGEOEGodvGQ">Смотреть видео</a>
              </div>
            </Col>
          </Row>

        </Container>

        <Container className='d-flex flex-column gap-3'>
          <Row>
            <h2 className='section-h2'>3d</h2>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <img className="img-fluid mb-3" src="./img/projects/3d-unilever.png" alt="project" />
            </Col>
            <Col xs={12} md={6}>

              <div className="black-rounded">
                <h4>Unilever - 3D видео для 3D кубов</h4>
                <p>Разработка 3д видеоролика для размещения на 3д кубах в торговых центрах с эффектом объема/присутствия. </p>
                <p>
                  Руководил производством видео ролика от концепции до реализации с учетом особенностей формата размещения.
                </p>
                <a className="watch-button unilever-3d-color" href="https://disk.yandex.ru/i/y5sANqs52aAkJw">Смотреть видео</a>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <img className="img-fluid mb-3" src="./img/projects/3d-icons.png" alt="project" />
            </Col>
            <Col xs={12} md={6}>

              <div className="black-rounded">
                <h4>3D иконки на Яндекс картах</h4>
                <p>Самостоятельная разработка 3D моделей для иконки на яндекс картах.</p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <img className="img-fluid mb-3" src="./img/projects/Lubimaya-Cola.png" alt="project" />
            </Col>
            <Col xs={12} md={6}>

              <div className="black-rounded">
                <h4>3D модели и ролики (видеообложки) для Ozon</h4>
                <p>Разработка 3D моделей для иконки на яндекс картах.</p>
                <ul>
                  <li>Управлял процессом разработки</li>
                  <li>Арт-дирекшен (рекомендации по композиции/текстурированию/моделингу/постановке света/технические правки)</li>
                </ul>
              </div>
            </Col>
          </Row>

        </Container>


        <Container className='d-flex flex-column gap-3'>
          <Row>
            <h2 className='section-h2'>Опыт работы (детали)</h2>
          </Row>
          <Row className="black-rounded d-flex flex-md-row">
            <Col xs={12} md={3}>
              <div>
                <h4>Менеджер проектов</h4>
                <p>PENA</p>
                <p>2023-2024</p>
              </div>
            </Col>
            <Col xs={12} md={9}>

              <div>
                <ul>
                  <li>
                    <p><strong>Проектирование и UX/UI-дизайн:</strong> создание прототипов и проектирование интерфейсов для сайтов и приложений.</p>
                  </li>
                  <li>
                    <p><strong>Аналитика и бизнес-процессы:</strong> анализ бизнес-процессов клиентов, выстраивание логики их цифровых продуктов.</p>
                  </li>
                  <li>
                    <p><strong>Работа с клиентами:</strong> курирование проектирования и разработки интерфейсов для Major-expert, Heinz Pro-chef, Lactalis Professional, Черноголовка.</p>
                  </li>
                  <li>
                    <p><strong>Управление командой:</strong> организация работы по созданию медиабаннеров, ключевых визуалов, 3D-обложек и лендингов.</p>
                  </li>
                  <li>
                    <p><strong>Проектный менеджмент:</strong> контроль обработки задач от клиентов, управление сроками выполнения.</p>
                  </li>
                  <li>
                    <p><strong>Спецпроекты:</strong> руководство разработкой метавселенной в Roblox для Kari.</p>
                  </li>
                  <li>
                    <p><strong>Тендерные проекты:</strong> подготовка предложений, разработка идей, расчет стоимости и презентация.</p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          <Row className="black-rounded d-flex flex-md-row">
            <Col xs={12} md={3}>
              <div>
                <h4>Менеджер проектов</h4>
                <p>АНО “Национальные приоритеты”</p>
                <p>2022-2022</p>
              </div>
            </Col>
            <Col xs={12} md={9}>

              <div>
                <ul>
                  <li>
                    <p><strong>Взаимодействие со стейкхолдерами:</strong> сбор и формализация требований для проекта.</p>
                  </li>
                  <li>
                    <p><strong>Создание технической документации:</strong> разработка технического задания для команды.</p>
                  </li>
                  <li>
                    <p><strong>Координация разработки:</strong> постановка задач команде, контроль выполнения.</p>
                  </li>
                  <li>
                    <p><strong>Финансовый контроль:</strong> управление бюджетом проекта.</p>
                  </li>
                  <li>
                    <p><strong>Документооборот:</strong> ведение документации и участие в закупочных процедурах.</p>
                  </li>
                </ul>

              </div>
            </Col>
          </Row>

          <Row className="black-rounded d-flex flex-md-row">
            <Col xs={12} md={3}>
              <div>
                <h4>Менеджер проектов</h4>
                <p>OMD OM Group</p>
                <small>Omnicom Group</small>
                <p>2019 - 2022</p>
              </div>
            </Col>
            <Col xs={12} md={9}>

              <div>
                <ul>
                  <li>
                    <p><strong>Координация digital-кампаний:</strong> управление разработкой и реализацией баннерной рекламы и сайтов для брендов.</p>
                  </li>
                  <li>
                    <p><strong>Аналитика и проектирование:</strong> анализ бизнес-процессов клиентов, разработка прототипов и написание технических заданий.</p>
                  </li>
                  <li>
                    <p><strong>Управление проектами:</strong> организация работы в таск-трекерах, контроль сроков и выполнения задач.</p>
                  </li>
                  <li>
                    <p><strong>Тендерные проекты:</strong> подготовка презентаций, защита концепций перед клиентами.</p>
                  </li>
                  <li>
                    <p><strong>Креативные решения:</strong> участие в разработке digital promo и спецпроектов.</p>
                  </li>
                  <li>
                    <p><strong>Международное взаимодействие:</strong> координация работы команд, включая глобальные офисы.</p>
                  </li>
                </ul>

              </div>
            </Col>
          </Row>

          <Row className="black-rounded d-flex flex-md-row">
            <Col xs={12} md={3}>
              <div>
                <h4>Менеджер проектов</h4>
                <p>Engage</p>
                <small>Publicis Group</small>
                <p>2019 - 2022</p>
              </div>
            </Col>
            <Col xs={12} md={9}>

              <div>
                <ul>
                  <li>
                    <p><strong>Разработка и адаптация функционала:</strong> координация работ по обновлению сайтов Nissan (ru, kz, by).</p>
                  </li>
                  <li>
                    <p><strong>Формирование технической документации:</strong> подготовка ТЗ, брифов, составление смет и таймингов.</p>
                  </li>
                  <li>
                    <p><strong>Управление процессами:</strong> организация работы в таск-трекерах, контроль задач команды разработки.</p>
                  </li>
                  <li>
                    <p><strong>Тестирование и контроль качества:</strong> проведение тестов и проверка реализованных функций.</p>
                  </li>
                  <li>
                    <p><strong>Международное взаимодействие:</strong> согласование требований с глобальной технической поддержкой, участие во встречах с международными офисами.</p>
                  </li>
                  <li>
                    <p><strong>Техническая поддержка:</strong> настройка хостингов и доменов, документооборот.</p>
                  </li>
                  <li>
                    <p><strong>Проектирование обновлений:</strong> участие в разработке новых решений для других клиентов.</p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          <Row className="black-rounded d-flex flex-md-row">
            <Col xs={12} md={3}>
              <div>
                <h4>Менеджер проектов</h4>
                <p>Empise</p>
                <p>2016 - 2018</p>
              </div>
            </Col>
            <Col xs={12} md={9}>

              <div>
                <p>веб разработка e-com сайтов на bitrix, wp</p>
                <ul>
                  <li>Сбор и анализ требований.</li>
                  <li>Оценка стоимости проекта.</li>
                  <li>Планирование сроков и этапов работ.</li>
                  <li>Формирование команды (backend, frontend, дизайнеры).</li>
                </ul>
              </div>
            </Col>
          </Row>

        </Container>
        <Container>
          <Row>
            <div className='black-rounded mt-3 mb-3'>
              <h1>Пишите звоните =)</h1>
              <div className="contacts d-flex flex-row align-items-start gap-3">
                <div className="d-flex align-items-center mb-2">
                  <img src="./img/icons/gmail.svg" width="32px" alt="email" className="me-2" />
                  <a href="mailto:an.vi.lisenkov@gmail.com" target='_blank'>an.vi.lisenkov@gmail.com</a>
                </div>
                <div className="d-flex align-items-center">
                  <img src="./img/icons/telegram.svg" width="32px" alt="telegram" className="me-2" />
                  <a href="https://t.me/anvilis" target='_blank'>@anvilis</a>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </Container>

    </div>
  );
}

export default App;